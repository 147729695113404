import React from 'react';
import {navigate} from 'gatsby';
import Spacer from '../components/Spacer';
import Button from '../components/Button';

const NotFoundPage = () => {
  const handleClickHome = () => {
    navigate('/home');
  };

  return (
    <>
      <Spacer />
      <div className={`h-96 w-full flex justify-center items-center`}>
        <Button title={'Home'} onClickBtn={handleClickHome} />
      </div>
    </>
  );
};

export default NotFoundPage;
